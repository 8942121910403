// official lifesize branding
$colors-lifesizeBlue: #2a2af1; // rgb(42, 42, 241)
$colors-squadronBlue: #0000b3; // rgb(0, 0, 179)
$colors-eurekaBlack: #1d2137; // rgb(29, 33, 55)
$colors-agileGreen: #5cc5ab; // rgb(92, 197, 171)
$colors-stickynoteYellow: #fee822; // rgb(254, 232, 34)
$colors-vividViolet: #9643da; // rgb(150, 67, 218)
$colors-alertRed: #e43e3e; // rgb(228, 62, 62)
$colors-warningOrange: #fc8b38; // rgb(252, 139, 56)
$colors-headlineBlack: #201727; // rgb(32, 23, 39)
$colors-squidInk: #323f4b; // rgb(50, 63, 75)
$colors-slate: #52606d; // rgb(82, 96, 109)
$colors-smoke: #758393; // rgb(117, 131, 147)
$colors-sentinel: #9aa5b1; // rgb(154, 165, 177)
$colors-concrete: #cbd2d9; // rgb(203, 210, 217)
$colors-missionFog: #dbe1e6; // rgb(219, 225, 230)
$colors-overcast: #eceef2; // rgb(236, 238, 242)
$colors-cloud: #f5f7fa; // rgb(245, 247, 250)
$colors-white: #ffffff; // rgb(255, 255, 255)
$colors-bright-green: #00d991; // rgb(0, 217, 145)
$colors-black: #000;

// old deprecated colors
$clear: rgba(0, 0, 0, 0);
$black: rgb(0, 0, 0);
$black-eighty: rgba($black, 0.8);
$black-fifteen: rgba($black, 0.15);
$black-fifty: rgba($black, 0.5);
$black-forty: rgba($black, 0.4);
$black-ninety: rgba($black, 0.9);
$black-seventy: rgba($black, 0.7);
$black-six: rgba($black, 0.06);
$black-sixty: rgba($black, 0.6);
$black-ten: rgba($black, 0.1);
$black-thirty: rgba($black, 0.3);
$black-twelve: rgba($black, 0.12);
$black-twenty-four: rgba($black, 0.24);
$black-twenty: rgba($black, 0.2);
$caribbeanGreen: rgb(0, 193, 139); // #00c18b
$darkishRed: rgb(177, 0, 4);
$darkPepper: rgb(221, 48, 48); // #dd3030
$firebrick: rgb(191, 7, 18);
$guardsmanRed: rgb(193, 0, 0); // #c10000
$indigo: rgb(73, 74, 210); // #494ad2
$kingfisher: rgb(41, 0, 136); // #290088
$martinique: rgb(52, 46, 82);
$mountainMeadow: rgb(23, 203, 160); // #18b878
$persianGreen: rgb(1, 163, 157); // #01a39d
$red: rgb(252, 15, 28);
$royalBlue: rgb(75, 69, 218); // #4b45da
$mediumSlateBlue: rgb(105, 99, 255); // #6963ff
$aliceBlue: rgb(235, 240, 244); // #ebf0f4
$chambray: rgb(63, 86, 113); // #3f5671
$rockBlue: rgb(152, 172, 195); // #98acc3
$slateBlue: rgb(86, 80, 215); // #5650d7 NEW OFFICIAL COLOR!
$tangerine: rgb(252, 125, 7); // #fc7d07
$treePoppy: rgb(255, 158, 22);
$white-eighty: rgba(255, 255, 255, 0.8);
$white-fifty: rgba(255, 255, 255, 0.5);
$white-forty: rgba(255, 255, 255, 0.4);
$white-ninety-four: rgba(255, 255, 255, 0.94);
$white-ninety: rgba(255, 255, 255, 0.9);
$white-one: rgba(255, 255, 255, 1);
$white-seventy: rgba(255, 255, 255, 0.7);
$white-sixty: rgba(255, 255, 255, 0.6);
$white-thirty: rgba(255, 255, 255, 0.3);
$white-twenty: rgba(255, 255, 255, 0.2);
$white-zero: rgba(255, 255, 255, 0);
$white: rgb(255, 255, 255);

$inputErrorIconRed: rgb(210, 51, 51);
$inputErrorRed: rgb(179, 21, 0);
$inputErrorRed-fifty: rgba(179, 21, 0, 0.5);
$inputErrorRed-eighty: rgba(179, 21, 0, 0.8);

$themeColorPrimary: $slateBlue;

// shades of grey ;)
$abbey-eight: rgba(75, 79, 84, 0.08);
$abbey-eighty: rgba(75, 79, 84, 0.8);
$abbey-fifty: rgba(75, 79, 84, 0.5);
$abbey-seventy: rgba(75, 79, 84, 0.7);
$abbey-sixty: rgba(75, 79, 84, 0.6);
$abbey-ten: rgba(75, 79, 84, 0.1);
$abbey-thirty: rgba(75, 79, 84, 0.3);
$abbey-twenty-five: rgba(75, 79, 84, 0.25);
$abbey: rgb(75, 79, 84); // #4b4f54
$almost-white: rgb(250, 250, 250); // #fafafa
$alto: rgb(216, 216, 216); // #d8d8d8
$aubergine-fifty: rgba(47, 42, 43, 0.5); // #2f2a2b
$aubergine-thirty: rgba(47, 42, 43, 0.3); // #2f2a2b
$charcoal: rgb(53, 53, 53); // #353535
$concrete-lighter: rgb(243, 243, 243); // #f3f3f3
$concrete-zero: rgba(242, 242, 242, 0);
$concrete: rgb(242, 242, 242); // #f2f2f2
$doveGrey: rgb(101, 101, 101); // #656565
$gallery: rgb(237, 237, 237); // #ededed
$granite-fifty: rgba(58, 62, 66, 0.5);
$granite: rgb(160, 160, 160); // #a0a0a0
$greyChateau: rgb(148, 154, 165); // #949aa5
$gunmetal: rgb(206, 206, 206); // #cecece
$mineShaft: rgb(51, 51, 51); // #333333
$mineshaft-thirty: rgba(51, 51, 51, 0.3);
$pigeonGrey: rgb(102, 102, 102); // #666666
$seashell: rgb(241, 241, 242); // #f1f1f2
$silver: rgb(204, 204, 204); // cccccc
$silverChalice: rgb(160, 160, 160); // #a0a0a0
$silverChaliceDark-fifty: rgba(174, 174, 174, 0.5); // #aeaeae
$silverChaliceDark: rgb(174, 174, 174); // #aeaeae
$space-gray-seventy: rgba(44, 44, 44, 0.7); // #2c2c2c
$thunder-eighty: rgba(35, 31, 32, 0.8);
$thunder-fifty: rgba(35, 31, 32, 0.5);
$thunder-five: rgba(35, 31, 32, 0.05);
$thunder-forty: rgba(35, 31, 32, 0.4);
$thunder-seventy: rgba(35, 31, 32, 0.7);
$thunder-sixty: rgba(35, 31, 32, 0.6);
$thunder-ten-solid: rgb(233, 233, 233); // #e9e9e9
$thunder-ten: rgba(35, 31, 32, 0.1);
$thunder-thirty: rgba(35, 31, 32, 0.3);
$thunder-twenty: rgba(35, 31, 32, 0.2);
$thunder: rgb(35, 31, 32); // #231f20
$topaz: rgb(124, 127, 142); // #7c7f8e
$meeting-details-label: rgb(42, 49, 62); // #2A313E
$meeting-details-value: rgb(63, 86, 113); // #3F5671
$storm-cloud: rgb(62, 76, 89); // #3e4c59

// latest greys
$ebony: rgb(42, 49, 62); // #2a313e
$greyChateau: rgb(148, 154, 165); // #949aa5

// where did these colors come from?
$nevada-fifty: rgba(111, 114, 118, 0.5);

// Lifesize Connect Plus
$connectPlus-blue: #003594;
$connectPlus-blueHover: #71b2c9;

// bridge css => js
// https://css-tricks.com/getting-javascript-to-talk-to-css-and-sass/
:export {
  abbey: $abbey;
  aliceBlue: $aliceBlue;
  black: $colors-black;
  chambray: $chambray;
  darkPepper: $darkPepper;
  ebony: $ebony;
  greyChateau: $greyChateau;
  lifesize: $themeColorPrimary;
  persianGreen: $persianGreen;
  rockBlue: $rockBlue;
  slateBlue: $slateBlue;
  tangerine: $tangerine;
  thunder: $thunder;
  treePoppy: $treePoppy;
  white: $colors-white;
  stormCloud: $storm-cloud;
  agileGreen: $colors-agileGreen;
  nimbusCloud: $colors-cloud;
  granite: $granite;
  brightGreen: $colors-bright-green;
  concrete: $colors-concrete;
  lifesizeBlue: $colors-lifesizeBlue;
  alertRed: $colors-alertRed;
}
