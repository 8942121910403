// Font Families
$font-family-primary: LsFont;

// Font Weights
$font-weight-bold: 700;
$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-regular: 400;

// Font Sizes
$font-size-text-tiny: 10px;
$font-size-text-small: 12px;
$font-size-text-normal: 14px;
$font-size-text-large: 16px;
$font-size-text-larger: 17px;
$font-size-text-huge: 18px;

$font-size-header-tiny: 20px; // h6
$font-size-header-small: 22px; // h5
$font-size-header-normal: 24px; // h4
$font-size-header-large: 30px; // h3
$font-size-header-largish: 32px;
$font-size-header-larger: 35px; // h2
$font-size-header-huge: 40px; // h1
