@import 'styles/_base.module.scss';

.toastify {
  background-color: #fae5e3 !important;
  color: #231f20;
  width: 425px;
  top: 50px;

  & a {
    margin: auto 0;
  }

  & div.reconnect {
    color: #ad4b47;
    font-weight: $font-weight-medium;
    padding-right: 10px;
  }
}

.toastifyBody {
  padding-left: 10px;
}
