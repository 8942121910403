/* use Sass mixins to define any commonly used css blocks
 * - when the style requires multiple properties to be defined
 * - when the style requires multiple properties or pseudo-selectors for cross-browser support
 * Note: http://sass-lang.com/documentation/file.SASS_REFERENCE.html#defining_a_mixin */

@keyframes spin360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin anim-spinner($color: $abbey, $diameter: 20px) {
  animation: spin360 1.1s infinite linear;
  border-color: $color transparent transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  display: inline-block;
  height: $diameter;
  vertical-align: middle;
  width: $diameter;
}

@mixin ellipsize {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin input-field($fieldWidth: 100%) {
  background: none;
  border: 0;
  border-bottom: 2px solid $abbey-thirty;
  color: $abbey;
  font-size: $font-size-text-large;
  padding: 0 15px 9px;
  transition: border 0.5s ease-out;
  width: $fieldWidth;

  @include placeholder-text($abbey-fifty);

  &:focus {
    border-bottom: 2px solid $themeColorPrimary;
    outline: none;
  }
}

@mixin placeholder-text($textColor: $thunder-fifty, $opacity: 1) {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $textColor;
    opacity: $opacity;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $textColor;
    opacity: $opacity;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $textColor;
    opacity: $opacity;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $textColor;
    opacity: $opacity;
  }
}

@mixin wrap-long-lines {
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  // This is the dangerous one in WebKit, as it breaks things wherever
  word-break: break-all;
  // Instead use this non-standard one
  word-break: break-word;

  // Add a hyphen where the word breaks, if supported
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  // respect white space
  white-space: pre-wrap;
}

@function spacingRel($units: 1) {
  @return $units * 0.5em;
}

@function spacing($units: 1) {
  @return $units * 8px;
}
