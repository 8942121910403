@import 'styles/_base.module.scss';

$diameter: 100px;

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.5;
  }
}

@keyframes scale {
  from {
    height: 0;
    width: 0;
  }

  to {
    width: $diameter;
    height: $diameter;
  }
}

.loadingContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 200;
  background-color: $themeColorPrimary;
}

.circle {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: $diameter;
  max-height: $diameter;
  opacity: 0;
  border-radius: 50%;
  background-color: #fff;
  animation: opacity 1s linear infinite alternate, scale 1s ease-in-out infinite alternate;
}

.circle:last-of-type {
  animation-delay: 1s;
}
